import React from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading } from "@chakra-ui/react";
import Card from "./Card";

const projects = [
  {
    title: "React To-do App",
    description:
      "A simple yet powerful application designed to help users manage their tasks and to-do lists efficiently. The main goal is to allow users to track, organize, and prioritize tasks, ensuring that nothing important is forgotten or overlooked",
    getImageSrc: () => require("../images/photo1a.png"),
  },
  {
    title: "E-commerce Product Page",
    description:
      "An e-commerce product page is a crucial part of an online shopping platform, designed to showcase individual products to potential customers. This page provides detailed information about the product, helping customers make informed purchasing decisions",
    getImageSrc: () => require("../images/photo2.png"),
  },
  {
    title: "Multi-Step Form",
    description:
      "A user interface design pattern used in web and mobile applications to break down a long or complex form into smaller, more manageable sections or steps. Instead of presenting all form fields on a single page, the multi-step form guides the user through a series of steps, each containing a subset of the overall form fields",
    getImageSrc: () => require("../images/photo3.png"),
  },
  {
    title: "Interactive Card Details Form",
    description:
      "A user interface element that allows users to securely enter and manage their payment card information in a visually engaging and user-friendly way. This type of form is commonly used in e-commerce websites, online services, and mobile applications where users need to provide credit or debit card details for transactions.",
    getImageSrc: () => require("../images/photo4a.png"),
  },
  
];

const ProjectsSection = () => {
  return (
    <FullScreenSection
      backgroundColor="#14532d"
      isDarkBackground
      p={8}
      alignItems="flex-start"
      spacing={8}
    >
      <Heading as="h1" id="projects-section">
        Featured Projects
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2,minmax(0,1fr))"
        gridGap={8}
      >
        {projects.map((project) => (
          <Card
            key={project.title}
            title={project.title}
            description={project.description}
            imageSrc={project.getImageSrc()}
          />
        ))}
      </Box>
    </FullScreenSection>
  );
};

export default ProjectsSection;
